<template>
  <div class="container vld-parent">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#114C8E"
    ></loading>
    <b-row>
      <h1 class="text-primary">Messages</h1>
    </b-row>

    <b-row>
      <div class="email-app" v-if="!errorLoading">
        <div class=" w-100 p-3" v-if="!messages || messages.length == 0">
          <p class="font-weight-bold text-center">
            There are no messages to display at this time.
          </p>
        </div>
        <main class="inbox " v-else>
          <ul class="messages">
            <li
              class="message"
              :class="{ unread: message.unread }"
              v-for="(message, index) in messages"
              :key="message.userMessageId"
              @click="onCardClick(message, index)"
            >
              <div class="actions">
                <span class="action">
                  <i class="fa fa-envelope-o" v-if="message.unread"></i>
                  <i class="fa fa-envelope-open-o" v-else></i>
                </span>
              </div>
              <div class="header">
                <span class="from mr-2"> {{ message.title }}</span>
                <span :class="getBadgeClass(message.category)">{{
                  message.category
                }}</span>
                <span class="date"
                  ><span class="fa fa-paper-clip"></span>
                  {{ formatDate(message.dateCreatedUtc) }}</span
                >
              </div>
              <!-- <div class="title">
                {{ message.title }}
       
              </div> -->
              <div class="description pt-2 border-blue-100">
                <b-collapse
                  :id="`message.userMessageId`"
                  :visible="index === expanded"
                >
                  <div v-html="message.message"></div>
                </b-collapse>
              </div>
            </li>
          </ul>
        </main>
      </div>

      <b-alert variant="danger" class="col-12" v-model="errorLoading">
        <h4 class="alert-heading">Error!</h4>
        <p>There was an error loading your messages.</p>
        <b-button variant="danger" size="sm" @click="loadMessages"
          >Click here to try again</b-button
        >
      </b-alert>
    </b-row>

    <!-- 
        <b-row align-h="between" class="my-3">
 
          <b-col md="6" class="my-1">
            <b-form inline class="float-md-right">
              <label class="mr-sm-2 col-form-label-sm" for="perPageSelect"
                >Per page:</label
              >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form>
          </b-col>
        </b-row> -->

    <!-- <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              last-number
              class="my-2"
            ></b-pagination> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { handleRequestError } from "@/shared/utils/response-error-handler";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { utcDateToLocal } from "@/shared/utils/date-formatter";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const UserMessagesRepository = RepositoryFactory.get("userMessagesRepository");

export default {
  components: {
    Loading,
    Event
  },

  mounted() {
    this.loadMessages(true);
    //this.timeout = setInterval(this.loadMessages, 30 * 1000);

    this.refreshInterval = setInterval(() => {
      this.loadMessages(false);
    }, 30 * 1000);
  },

  data() {
    return {
      refreshInterval: null,
      isLoading: false,
      expanded: null,
      errorLoading: false
    };
  },
  methods: {
    ...mapActions("notification", ["setMessages", "setNewMessageCount"]),
    async loadMessages(showLoading) {
      if (showLoading) this.isLoading = true;

      try {
        var requestData = {
          jwtToken: this.$store.state.user.jwtToken
        };
        var result = await UserMessagesRepository.get(requestData);
        this.setMessages(result.results);
        this.localSetNewMessageCount();
        this.errorLoading = false;
      } catch (error) {
        this.errorLoading = true;
      }

      this.isLoading = false;
    },
    async onCardClick(message, index) {
      if (this.expanded === index || !message.unread) {
        this.expanded == index
          ? (this.expanded = null)
          : (this.expanded = index);
        return;
      }

      try {
        var requestData = {
          jwtToken: this.$store.state.user.jwtToken,
          id: message.userMessageId
        };
        await UserMessagesRepository.markAsRead(requestData);

        message.unread = false;

        this.localSetNewMessageCount();
      } catch (error) {console.log(error)}

      this.expanded == index ? (this.expanded = null) : (this.expanded = index);
    },

    localSetNewMessageCount() {
      let result = this.messages.filter(word => word.unread == true);
      this.setNewMessageCount(result.length);
    },
    getBadgeClass(category) {
      switch (category) {
        case "Website Change - New Feature":
        case "Website Change - General":
          return "badge badge-success";

        case "General Notification":
          return "badge badge-info";

        case "Reminder":
          return "badge badge-warning";

        case "Outage":
        case "Order/Delivery Issue":
        case "Invoice Issue":
          return "badge badge-danger";

        default:
          return "badge badge-Secondary";
      }
    },
    formatDate(date) {
      return utcDateToLocal(date);
    }
  },

  computed: {
    ...mapState("notification", ["messages"])
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  }
};
</script>

<style scoped></style>
